<template>
  <div id="loginPage" :class="hasBackground">
    <div v-if="corruptedUserData" class="centered">The entered user is not valid, <span class="underlineUrl" @click = ssoLogout() >click here</span> to log in as a different user</div>
    <div v-else-if="userAuthenticated"><router-link to="/dashboard/demo">GO TO DEMO PAGE</router-link></div>
    <div v-else>
      <div v-if="hasLoginImage" class="container">
        <div class="col centered">
          <img
              class="loginImage"
              :src="loginLogoImage"
              alt="LOGO"
          />
          <h1 id="text">{{ loginTitle }}</h1>
          <h2 id="subtitle" style="font-weight:normal">{{ loginSubtitle }}</h2>
        </div>
        <p id="link">
          <a :href="loginLink" target="_blank">
            {{ loginLinkText }}
            <img
                v-if="hasLoginIcon"
                :src="loginIcon"
                style="height:20px"
                alt="LOGO"
            >
          </a>
        </p>
        </div>
      <div v-else>NOT LOGGED IN</div>
    </div>
    <div class="col w" v-if="isRegistrationEnabled">
      <p class="highlight-text centered"> Don't have an account? <router-link to="signup">Click Here</router-link>
      </p>
  </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import store from '../../../app-store'
export default {
  name: 'Login',
  data () {
    return {
      userAuthenticated: false,
      isUserAuthenticated: store.state.userAuthenticated,
      hasLoginImage: (process.env.VUE_APP_LOGIN_IMAGE != null),
      hasLoginIcon: (process.env.VUE_APP_LOGIN_ICON != null),
      isRegistrationEnabled: (process.env.VUE_APP_REGISTRATION_ENABLED === 'true')
    }
  },
  async mounted () {
    await store.dispatch('getUserAuthStatus')
  },
  computed: {
    hasBackground () {
      if (process.env.VUE_APP_LOGIN_BACKGROUND != null) {
        return 'background'
      }
      return 'login-container'
    },
    ...mapState(['corruptedUserData']),
    loginTitle () {
      /* istanbul ignore next */
      return process.env.VUE_APP_LOGIN_TITLE
    },
    loginSubtitle () {
      /* istanbul ignore next */
      return process.env.VUE_APP_LOGIN_SUBTITLE
    },
    loginLink () {
      /* istanbul ignore next */
      return process.env.VUE_APP_LOGIN_LINK
    },
    loginIcon () {
      /* istanbul ignore next */
      if (this.hasLoginIcon) {
        return require(process.env.VUE_APP_LOGIN_ICON)
      }
      return ''
    },
    loginLinkText () {
      /* istanbul ignore next */
      return process.env.VUE_APP_LOGIN_LINK_TEXT
    },
    loginLogoImage () {
      if (this.hasLoginImage) {
        return require(process.env.VUE_APP_LOGIN_IMAGE)
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['eraseCorruptedUserData', 'logout']),
    setUserAuthenticated (val) {
      this.userAuthenticated = val
    },
    ssoLogout () {
      this.eraseCorruptedUserData()
      const url = this.logout()
      window.location.replace(url)
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  position: relative;
  top: 100px;
  align-items: center;
}
.w {
    padding-top: 150px;
}
.centered {
    text-align: center;
}
.loginImage {
    height: 180px;
    margin-bottom:15px;
}
.highlight-text {
  color: var(--text-primary-color);
}
#subtitle{
    font-size:18px;
}
#text {
    font-family: "Segoe UI", "Trebuchet MS", Helvetica, Tahoma, Geneva, sans-serif;
    font-size: 50px;
    font-weight: 500;
    margin-bottom:15px;
}
#link {
    margin-top: 10px;
    text-align: center;
    bottom:0;
}
.background {
    background: url(~@/assets/homepageBackground.jpg) center center no-repeat;
    background-size: cover;
    transform: scale(1.02);
    min-width: 100%;
    height: 100%;
}
.underlineUrl{
  color:blue;
  text-decoration: underline;
  cursor:pointer;
}
</style>
